import type { FC } from "react";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import QuestionnairePreviewCubit from "src/state/QuestionnairePreviewCubit/QuestionnairePreviewCubit";
import { BlocProvider, useBloc } from "src/state/state";
import CustomQuestionnaire from "src/ui/components/CustomQuestionnaire/CustomQuestionnaire";

const CustomQuestionnaireStandalone: FC = () => {
  const [, questionnaireBloc] = useBloc(QuestionnairePreviewCubit, {
    create: () => new QuestionnairePreviewCubit()
  });
  const { id = "" } = useParams<{ id: string }>();

  const questionnaireInstanceId = useMemo(() => {
    const url = new URL(window.location.href);
    const instanceId = url.searchParams.get("instanceId");
    return instanceId ? `:${instanceId}` : ":standalone";
  }, []);

  return (
    <BlocProvider bloc={questionnaireBloc}>
      <CustomQuestionnaire
        disableTracking
        disableAutoSubmit
        preventLoadingUserAnswers
        instanceId={questionnaireInstanceId}
        autoSkip={false}
        formLike
        id={id}
        onSubmit={async (): Promise<boolean> => {
          return false;
        }}
      ></CustomQuestionnaire>
    </BlocProvider>
  );
};

export default CustomQuestionnaireStandalone;
